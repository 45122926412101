@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-custom-gradient {
    @apply bg-[radial-gradient(circle_at_20%_40%,rgba(255,87,51,0.8),transparent),radial-gradient(circle_at_80%_60%,rgba(141,27,177,0.8),transparent),radial-gradient(circle_at_50%_70%,rgba(51,87,255,0.8),transparent),#1e1e1e];
  }
}
iframe {
  border-radius: 20px;
}
@font-face {
  font-family: "Nohemi";
  src: url("./fonts/nohemi-light.woff") format("woff"),
    url("./fonts/nohemi-light.ttf") format("truetype"),
    url("./fonts/nohemi-light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Nohemi";
  src: url("./fonts/nohemi-medium.woff") format("woff"),
    url("./fonts/nohemi-medium.ttf") format("truetype"),
    url("./fonts/nohemi-medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Nohemi";
  src: url("./fonts/nohemi-regular.woff") format("woff"),
    url("./fonts/nohemi-regular.ttf") format("truetype"),
    url("./fonts/nohemi-regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nohemi";
  src: url("./fonts/nohemi-thin.woff") format("woff"),
    url("./fonts/nohemi-thin.ttf") format("truetype"),
    url("./fonts/nohemi-thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./img/bodybg.avif");
  background-size: cover; /* or 'contain', '100% 100%', etc. depending on your needs */
  background-position: center;
  background-repeat: no-repeat;
  font-family: "Nohemi", sans-serif;
  overflow-y: overlay;
}

.values-bg {
  background: url("./img/aboutbg3.png");
  background-size: cover; /* or 'contain', '100% 100%', etc. depending on your needs */
  background-position: center;
  background-repeat: no-repeat;
}
.team-bg {
  background: url("./img/teambg.png");
  background-size: cover; /* or 'contain', '100% 100%', etc. depending on your needs */
  background-position: center;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* slider.css */

.slick-slide {
  padding: 0 5px; /* Her slide için padding ekler */
}

.slick-list {
  overflow: hidden;
}
